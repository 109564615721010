<template>
  <div class="common-search">
    <div class="search-input">
      <!-- <input v-on:keyup.enter="search" v-model="keyword" class="keyword" type="text" :placeholder="placeholder" @input="input"> -->
      <el-input
        @clear="search"     
        @keyup.enter.native="search"
        @input="input"
        :placeholder="placeholder"
        v-model="keyword" class="keyword"
        clearable>
      </el-input>
    </div>
    <div @click="search" class="search-btn">
      <img src="@/assets/img/home/search.png" alt="" class="icon" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  props: {
    placeholder: String,
    value: String
  },
  computed: {
    keyword: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:value', val)
      }
    }
  },
  methods: {
    search() {
      this.$emit('search')
    },
    input() {
      // this.$emit('input')
    }
  }
}
</script>
<style lang="scss">
.common-search {
  .keyword {
    height: 100%;
    input {
      height: 100%!important;
      font-size: .16rem!important;
      border: 0 none!important;
      padding: 0 .2rem!important;
      border-radius: 0.24rem 0 0 0.24rem!important;
      transition: all .5s;
      line-height: .48rem!important;     
    }
  }
}
</style>
<style scoped lang="scss">
.common-search {
  width: 3.6rem;
  height: 0.48rem;
  line-height: 0;
  display: flex;
  align-items: center;
  .search-input {
    height: 100%;
    flex: 1;
    border: 0 none;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    border-radius: 0.24rem 0 0 0.24rem;
    overflow: hidden;
    input {
      width: 100%;
      height: pxToRem(48);
      line-height: pxToRem(46);
      border-radius: 0.24rem 0 0 0.24rem;
      padding: 0 0.3rem;
      font-size: 0.16rem;
      border: 1px solid #ffffff;
      transition: all 0.3s;
      outline: none;
      background: none;
      box-shadow: none;
      appearance: none;
      -webkit-appearance: none;
      &:focus {
        border: 1px solid $orange;
        background: #ffffff;
        /* 解决input按钮自动填充后 浏览器渲染的默认背景问题 */
        box-shadow: inset 0 0 0 1000px #ffffff !important;
      }
    }
  }
  .search-btn {
    height: pxToRem(48);
    line-height: pxToRem(48);
    width: 0.8rem;
    border-radius: 0 pxToRem(24) pxToRem(24) 0;
    background: $orange;
    text-align: center;
    cursor: pointer;
    img {
      width: pxToRem(18);
    }
  }
}
</style>